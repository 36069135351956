@charset "UTF-8";
@import "../common/_settings/_setting";

/**
 * index
 */
body[id="index"] {
  @include mq-sp {
    .spContents {
      position: relative;
      z-index: 1;
    } //spContents
    .-wrapper {
      position: relative;
    } //wrapper
    .footer-inner {
      padding-left: 0;
      padding-right: 0;
    } //footer-inner
    .bg {
      position: absolute;
      z-index: 0;
      &.bg01 {
        top: 0;
      } //bg01
      &.bg02 {
        bottom: 0;
      } //bg01
    } //bg
    .contactWrap {
      position: relative;
      .sp_contactTxt {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        left: 0;
      } //sp_contactTxt
    } //contactWrap
  } //sp

  header {
    @include mq-pc {
      position: absolute;
      z-index: 1;
      width: vw(416);
      right: vw(433);
      top: vw(78);
    } //pc
    @include mq-max {
      max-width: rem(416);
    } //wide
    @include mq-sp {
    } //sp
  } //header
  .bg {
    &.-pos {
      position: relative;
    } //-pos
    &.-bg01 {
      position: absolute;
      top: 0;
      left: 0;
      @include mq-pc {
        width: vw(1308);
      } //pc
      @include mq-max {
        max-width: rem(1308);
      } //wide
      @include mq-sp {
        width: svw(300);
      } //sp
    } //-bg01
    &.-bg02 {
      position: absolute;
      bottom: 0;
      right: 0;
      @include mq-pc {
        width: vw(912);
      } //pc
      @include mq-max {
        max-width: rem(912);
      } //wide
      @include mq-sp {
        width: svw(300);
      } //sp
    } //-bg02
  } //bg
  .l-section {
    @include mq-pc {
      padding-top: vw(632);
    } //pc
    @include mq-max {
      padding-top: rem(632);
    } //wide
    @include mq-sp {
      padding-top: svw(100);
    } //sp
  } //l-section
  .l-main {
    @include mq-pc {
      padding-bottom: vw(103);
    } //pc
    @include mq-max {
      padding-bottom: rem(103);
    } //wide
  } //footer
  footer {
    position: relative;
    z-index: 1;
    // figure {
    //   border-top: 1px solid #dddddd;
    //   @include mq-pc {
    //     padding-top: vw(17);
    //   } //pc
    //   @include mq-max {
    //     padding-top: rem(17);
    //   } //wide
    //   img {
    //     @include mq-pc {
    //       width: vw(50);
    //     } //pc

    //     @include mq-max {
    //       width: rem(50);
    //     } //wide
    //   }
    // }
  }
  .section {
    position: relative;
    z-index: 1;

    &.-contact {
      @include mq-pc {
        margin-bottom: vw(323);
      } //pc
      @include mq-max {
        margin-bottom: rem(323);
      } //wide
    } //contact
  } //section
} //index
